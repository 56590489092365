<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Datepicker from "vue3-datepicker";

import appConfig from "@/app.config";
import { DateTime } from "luxon";
import reportSvc from "@/services/report";

/**
 * Customers component
 */
export default {
  page: {
    title: "Report",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Datepicker },
  data() {
    return {
      title: "Report",
      userActivity: {
        startDate: new Date(),
        endDate: new Date(),
      },
      blogDetail: {
        startDate: new Date(),
        endDate: new Date(),
      },
      blogPopular: {
        startDate: new Date(),
        endDate: new Date(),
      },
      blogByCategory: {
        startDate: new Date(),
        endDate: new Date(),
      },
      tagPopular: {
        startDate: new Date(),
        endDate: new Date(),
      },
      blogPublish: {
        startDate: new Date(),
        endDate: new Date(),
      },
    };
  },
  computed: {
    userActivityLowerDate() {
      return this.userActivity.startDate !== ""
        ? this.userActivity.startDate
        : new Date();
    },
    blogDetailLowerDate() {
      return this.blogDetail.startDate !== ""
        ? this.blogDetail.startDate
        : new Date();
    },
    blogPopularLowerDate() {
      return this.blogPopular.startDate !== ""
        ? this.blogPopular.startDate
        : new Date();
    },
    blogByCategoryLowerDate() {
      return this.blogByCategory.startDate !== ""
        ? this.blogByCategory.startDate
        : new Date();
    },
    tagPopularLowerDate() {
      return this.tagPopular.startDate !== ""
        ? this.tagPopular.startDate
        : new Date();
    },
    blogPublishLowerDate() {
      return this.blogPublish.startDate !== ""
        ? this.blogPublish.startDate
        : new Date();
    },
  },
  methods: {
    userActivityDownload() {
      const startDate = DateTime.fromJSDate(
        this.userActivity.startDate
      ).toFormat("yyyy-MM-dd");
      const endDate = DateTime.fromJSDate(this.userActivity.endDate).toFormat(
        "yyyy-MM-dd"
      );

      reportSvc.downloadUserActivity(startDate, endDate).then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `user-activity_${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    blogDetailDownload() {
      const startDate = DateTime.fromJSDate(this.blogDetail.startDate).toFormat(
        "yyyy-MM-dd"
      );
      const endDate = DateTime.fromJSDate(this.blogDetail.endDate).toFormat(
        "yyyy-MM-dd"
      );

      reportSvc.downloadBlogDetail(startDate, endDate).then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `blog-detail_${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    blogPopularDownload() {
      const startDate = DateTime.fromJSDate(
        this.blogPopular.startDate
      ).toFormat("yyyy-MM-dd");
      const endDate = DateTime.fromJSDate(this.blogPopular.endDate).toFormat(
        "yyyy-MM-dd"
      );

      reportSvc.downloadBlogPopular(startDate, endDate).then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `blog-popular_${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    blogByCategoryDownload() {
      const startDate = DateTime.fromJSDate(
        this.blogByCategory.startDate
      ).toFormat("yyyy-MM-dd");
      const endDate = DateTime.fromJSDate(this.blogByCategory.endDate).toFormat(
        "yyyy-MM-dd"
      );

      reportSvc.downloadBlogCategory(startDate, endDate).then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `blog-by-category_${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    tagPopularDownload() {
      const startDate = DateTime.fromJSDate(this.tagPopular.startDate).toFormat(
        "yyyy-MM-dd"
      );
      const endDate = DateTime.fromJSDate(this.tagPopular.endDate).toFormat(
        "yyyy-MM-dd"
      );

      reportSvc.downloadTagPopular(startDate, endDate).then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `tag-popular_${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    blogPublishDownload() {
      const startDate = DateTime.fromJSDate(
        this.blogPublish.startDate
      ).toFormat("yyyy-MM-dd");
      const endDate = DateTime.fromJSDate(this.blogPublish.endDate).toFormat(
        "yyyy-MM-dd"
      );

      reportSvc
        .downloadBlogDetailByStatusPublished(startDate, endDate)
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `blog-detail_published_${Date.now()}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <!----------- REPORT USER ACTIVITY ----------->
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">User Activity</h4>
            <div class="row">
              <div class="col-md-4">
                <b-form-group class="mb-3" label="Start Date">
                  <datepicker
                    v-model="userActivity.startDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="End Date">
                  <datepicker
                    v-model="userActivity.endDate"
                    :lower-limit="userActivityLowerDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="&nbsp;">
                  <b-button
                    class="btn btn-success ms-auto"
                    @click="userActivityDownload()"
                    >Download</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!----------- REPORT BLOG DETAIL ----------->
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Blog Detail</h4>
            <div class="row">
              <div class="col-md-4">
                <b-form-group class="mb-3" label="Start Date">
                  <datepicker
                    v-model="blogDetail.startDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="End Date">
                  <datepicker
                    v-model="blogDetail.endDate"
                    :lower-limit="blogDetailLowerDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="&nbsp;">
                  <b-button
                    class="btn btn-success ms-auto"
                    @click="blogDetailDownload()"
                    >Download</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!----------- REPORT BLOG POPULAR ----------->
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Blog Popular</h4>
            <div class="row">
              <div class="col-md-4">
                <b-form-group class="mb-3" label="Start Date">
                  <datepicker
                    v-model="blogPopular.startDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="End Date">
                  <datepicker
                    v-model="blogPopular.endDate"
                    :lower-limit="blogPopularLowerDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="&nbsp;">
                  <b-button
                    class="btn btn-success ms-auto"
                    @click="blogPopularDownload()"
                    >Download</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!----------- REPORT BLOG BY CATEGORY ----------->
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Blog By Category</h4>
            <div class="row">
              <div class="col-md-4">
                <b-form-group class="mb-3" label="Start Date">
                  <datepicker
                    v-model="blogByCategory.startDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="End Date">
                  <datepicker
                    v-model="blogByCategory.endDate"
                    :lower-limit="blogByCategoryLowerDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="&nbsp;">
                  <b-button
                    class="btn btn-success ms-auto"
                    @click="blogByCategoryDownload()"
                    >Download</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!----------- REPORT TAG POPULAR ----------->
      <div class="col-6" style="height: 350px">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Tag Popular</h4>
            <div class="row">
              <div class="col-md-4">
                <b-form-group class="mb-3" label="Start Date">
                  <datepicker
                    v-model="tagPopular.startDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="End Date">
                  <datepicker
                    v-model="tagPopular.endDate"
                    :lower-limit="tagPopularLowerDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="&nbsp;">
                  <b-button
                    class="btn btn-success ms-auto"
                    @click="tagPopularDownload()"
                    >Download</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!----------- REPORT PUBLISH ----------->
      <div class="col-6" style="height: 350px">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Blog Publish</h4>
            <div class="row">
              <div class="col-md-4">
                <b-form-group class="mb-3" label="Start Date">
                  <datepicker
                    v-model="blogPublish.startDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="End Date">
                  <datepicker
                    v-model="blogPublish.endDate"
                    :lower-limit="blogPublishLowerDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group class="mb-3" label="&nbsp;">
                  <b-button
                    class="btn btn-success ms-auto"
                    @click="blogPublishDownload()"
                    >Download</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
