import axios from "./index";

export default {
  downloadUserActivity(startDate, endDate) {
    return axios({
      url: `/v1/report/user_activity?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
      responseType: "blob",
    });
  },
  downloadBlogDetail(startDate, endDate) {
    return axios({
      url: `/v1/report/blog_detail?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
      responseType: "blob",
    });
  },
  downloadBlogPopular(startDate, endDate) {
    return axios({
      url: `/v1/report/blog_popular?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
      responseType: "blob",
    });
  },
  downloadBlogCategory(startDate, endDate) {
    return axios({
      url: `/v1/report/blog_category?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
      responseType: "blob",
    });
  },
  downloadTagPopular(startDate, endDate) {
    return axios({
      url: `/v1/report/tag_popular?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
      responseType: "blob",
    });
  },
  downloadBlogDetailByStatusPublished(startDate, endDate) {
    return axios({
      url: `/v1/report/blog_detail_published?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
      responseType: "blob",
    });
  },
};
